import React, { ReactNode } from "react";
import styles from "./Navbar.module.scss";

export type NavbarItemProps = {
    title?: ReactNode;
    onClick?: () => void;
}

const NavbarItem = ({title, onClick}: NavbarItemProps) => {
  return (
    <div className={styles.navbarItem} onClick={onClick}>
      <div className="navbarItemTitle">
        {title}
      </div>
    </div>
  );
}

export default NavbarItem;