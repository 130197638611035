import classnames from "classnames";
import { FC } from "react";
import styles from "./About.module.scss";
import PageTitle from "../../components/page/PageTitle";

export type AboutProps = {
    className?: string;
}

const About: FC<AboutProps> = ({className}) => {
    return (
        <div className={classnames(styles.about, className)}>
           <PageTitle pageTitle={"All About Us"}/> 
           <div className={styles.aboutContent}>A family-owned and operated company, Bur-Jes was founded in 1997. A specialist in the industry with over 25 years' experience.</div>
           <div className={styles.aboutContent}>Since our company began in 1997, we have worked hard to supply you with the daily living aids and mobility equipment you need to help you live more independently for longer. With a wide range of products, we have something for everyone to help make daily life that little bit easier.</div>
           <div className={styles.aboutContent}>We continually adapt to an ever-changing market. Ensuring we supply and maintain the latest products brought to the market whilst keeping proven products at the core of our business</div>
           <div className={styles.aboutContent}>Our aim is to provide you with flexibility, choice and options.</div>
           <div className={styles.aboutContent}>Our products focus on improving your day-to-day needs, and to fit into your private lifestyle, allowing you to make the adjustments that are necessary to fit your specific requirements.</div>
           <PageTitle pageTitle={"Our Vision"}/>
           <div className={styles.aboutContent}>To give you, and those who care for you, the products, services and support you need to live a positive and dignified life.</div>
           
        </div>
    )
}

export default About;