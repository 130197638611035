import classnames from "classnames";
import { FC } from "react";
import styles from "./Contact.module.scss";
import PageTitle from "../../components/page/PageTitle";

export type ContactProps = {
    className?: string;
}

const Contact: FC<ContactProps> = ({className}) => {
    return (
        <div className={classnames(styles.contact, className)}>
          <PageTitle pageTitle={"Contact Us"}/> 
          <div className={styles.contactContent}>Main Line: 01604 811188</div>
           <div className={styles.contactContent}>Mobile: 07968 366038 (Claire Mattock)</div>
           <div className={styles.contactContent}>Email: <a href='mailto:bur-jes@hotmail.co.uk'>bur-jes@hotmail.co.uk</a></div>
           <div className={styles.contactContent}></div>
           <div className={styles.contactContent}></div>
        </div>
    )
}

export default Contact;