import BurJesLogo from "../../content/images/BurJesRedNoBg.png";
import styles from "./Logo.module.scss";

const Logo = () => {
    return (
        <img 
            className={styles.logo}
            draggable={false} 
            src={BurJesLogo} 
            alt={"Bur-Jes Logo"}
        />
    )
}

export default Logo;