import React, { ReactNode } from "react";
import NavbarItem from "./NavbarItem";
import { useNavigate } from "react-router-dom";
import styles from "./Navbar.module.scss";

export interface NavbarProps {
    children?: ReactNode;
}

const Navbar = (props: NavbarProps) => {
  const navigate = useNavigate();
  return (
    <div className={styles.navbar}>
        <NavbarItem title={"Home"} onClick={() => navigate({pathname: '/'})}/>
        <NavbarItem title={"About"} onClick={() => navigate({pathname: 'about'})}/>
        <NavbarItem title={"Portfolio"} onClick={() => navigate({pathname: 'portfolio'})}/>
        <NavbarItem title={"Contact"} onClick={() => navigate({pathname: 'contact'})}/>
    </div>
  );
}

export default Navbar;