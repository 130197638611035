import classnames from "classnames";
import { FC } from "react";
import styles from "./PageTitle.module.scss";

export type PageTitleProps = {
    className?: string;
    pageTitle?: string;
}

const PageTitle: FC<PageTitleProps> = ({className, pageTitle}) => {
    return (
        <div className={classnames(styles.pageTitle, className)}>
           <div className={styles.pageTitleLabel}>{pageTitle}</div>
        </div>
    )
}

export default PageTitle;