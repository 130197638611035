import './App.css';
import Page from './components/page/Page';
import Home from './pages/home/Home';
import { Route, Routes } from 'react-router-dom';
import About from './pages/about/About';
import Navbar from './pages/navigation/Navbar';
import Portfolio from './pages/portfolio/Portfolio';
import Contact from './pages/contact/Contact';
import Logo from './components/logo/Logo';

function App() {
  return (
    <Page>
      <Page.Header>
        <Logo />
        <Navbar/>
      </Page.Header>
      <Page.Content>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="contact" element={<Contact />} />
          <Route
            path="*"
            element={<h1 className="not-found">Page Not Found</h1>}
          />
      </Routes>
      </Page.Content>
    </Page>
  );
}

export default App;
