import classnames from "classnames";
import { FC } from "react";
import styles from "./Portfolio.module.scss";
import PageTitle from "../../components/page/PageTitle";
import { Gallery, GalleryProps, Image } from "react-grid-gallery";
import Tabs from "../../components/tabs/Tabs";
import TabNav from "../../components/tabs/TabNav";
import Tab from "../../components/tabs/Tab";
import TabPanels from "../../components/tabs/TabPanels";
import TabContent from "../../components/tabs/TabContent";
import Image1 from "../../content/images/portfolio/externalPaving6.jpg";
import Image2 from "../../content/images/portfolio/externalStep.jpg";
import Image3 from "../../content/images/portfolio/externalStepsWithHandrails.jpg";
import { images } from "./images";

export type PortfolioProps = {
    className?: string;
}

const Portfolio: FC<PortfolioProps> = ({className}) => {
    // const images = [
    //     {
    //        src: "http://localhost:3000/content/images/portfolio/externalPavings6.jpg",
    //        width: 300,
    //        height: 300,
    //        alt: "External pavings",
    //     },
    //     {
    //        src: "../../../content/images/portfolio/externalStep.jpg",
    //        width: 300,
    //        height: 300,
    //        alt: "External step without handrail",
    //     },
    //     {
    //        src: "../../../content/images/portfolio/externalStepsWithHandrails.jpg",
    //        width: 300,
    //        height: 300,
    //        alt: "External steps with dual handrails",
    //     },
    //  ];

    //  const items: GalleryProps<Image>[] = [
    //     {
    //        src: Image1,
    //     //    caption: "External pavings",
    //     //    key: 1,
    //        width: 300,
    //        height: 300,
    //        alt: "External pavings",
    //     },
    //     {
    //        src: Image2,
    //     //    caption: "External step without handrail",
    //     //    key: 2,
    //        width: 300,
    //        height: 300,
    //        altText: "External step without handrail",
    //     },
    //     {
    //        src: Image3,
    //     //    caption: "External steps with dual handrails",
    //     //    key: 3,
    //        width: 300,
    //        height: 300,
    //        altText: "External steps with dual handrails",
    //     },
    //  ];

    return (
        <div className={classnames(styles.portfolio, className)}>
           <PageTitle pageTitle={"What we do"} />
           <div className={styles.portfolioContent}>Portfolio is coming soon, thank you for your patience</div>
           <Gallery images={images}/>


        </div>
    )
}

export default Portfolio;