import React, { FC, HTMLAttributes } from "react";
import styles from "./Page.module.scss";
import classnames from "classnames";

export type PageProps = HTMLAttributes<HTMLDivElement> & {
    className?: string;
}

const Page: FC<PageProps> = ({className, children}) => {
    return (
        <div className={classnames(styles.page, className)}>
            {children}
        </div>
    )
}

export type HeaderProps = HTMLAttributes<HTMLDivElement> & {
    className?: string;
}

const Header: FC<HeaderProps> = ({className, children}) => {
    return (
        <div className={classnames(styles.pageHeader, className)}>
            {children}
        </div>
    )
}

export type ContentProps = HTMLAttributes<HTMLDivElement> & {
    className?: string;
}

const Content: FC<ContentProps> = ({className, children}) => {
    return (
        <div className={classnames(styles.pageContent, className)}>
            {children}
        </div>
    )
}

export type FooterProps = HTMLAttributes<HTMLDivElement> & {
    className?: string;
}

const Footer: FC<FooterProps> = ({className, children}) => {
    return (
        <div className={classnames(styles.pageFooter, className)}>
            {children}
        </div>
    )
}

export default Object.assign(Page, {
    Header,
    Content,
    Footer
})